<template>
  <div class="login">
    <div class="box" id="snowzone"></div>
    <div class="head">
      <div class="left">
        <div class="date">{{ shijian }}</div>
      </div>
      <div class="title">TEEMAX乐园业务数据分析系统</div>
      <!-- <div class="right"></div> -->
    </div>
    <!-- <img src="../assets/images/login/paul.png" > -->
    <div class="login_box" v-if="isShow == 'zhh'">
      <div class="erweima" @click="logintab('wx')">
        <img src=".././assets/images/login/icon-06.png" alt="" />
      </div>
      <div class="title">
        <img src=".././assets/images/login/icon-07.png" alt="" />
      </div>
      <div class="txt">欢迎登陆</div>
      <div class="userName">
        <div class="icon">
          <img src=".././assets/images/login/icon-04.png" alt="" />
        </div>
        <div class="border"></div>
        <input
          type="text"
          v-model="user"
          placeholder="请输入账户名称"
          @keyup.enter="Login"
        />
      </div>
      <div class="userName">
        <div class="icon">
          <img src=".././assets/images/login/icon-05.png" alt="" />
        </div>
        <div class="border"></div>
        <input
          type="password"
          v-model="password"
          placeholder="请输入你的密码"
          @keyup.enter="Login"
        />
      </div>
      <div class="button" @click="Login">登录</div>
    </div>

    <div class="login_box" v-else>
      <div class="erweima" @click="logintab('zhh')">
        <img src=".././assets/images/login/icon-08.png" alt="" />
      </div>
      <div class="title">
        <img src=".././assets/images/login/icon-07.png" alt="" />
      </div>
      <div class="erweima1">
        <div class="expireBox" @click="updateQrcode" v-if="isExpire">
          <img src="../assets/images/update.png" alt="" />
        </div>
        <!-- <QrCode style="width: 100%; height: 100%" :width="qrcodeSizeFnc()" :height="qrcodeSizeFnc()" id="QrCode" :text="qrCode" /> -->
        <img :src="imgUrl + 'appfile' +qrCode" alt="">
      </div>
      <div class="txt1">微信扫码登录</div>
    </div>
    <div class="FixedImg loginTips" v-if="loginblock" @click="loginyab">
      <span>{{ loginco }}</span>
      <p>×</p>
    </div>
  </div>
</template>
<script>
import eventBus from "@/server/eventBus";
import headTop from "@/components/headTop.vue";
import Api from "@/server";
import QrCode from "@/components/QrCode.vue";
export default {
  name: "login",
  components: {
    headTop,
    QrCode
  },
  data() {
    let qrTime = null;
    return {
      shijian: "",
      imgUrl: Api.imgUrl,
      auth_code: "",
      // readonly: true,

      // 登录结果提示
      loginblock: false,
      loginco: "",

      logintabVal: "zhh", //	登入方式

      // 账号密码登录
      user: "",
      password: "",
      code: "",
      assetsimages: "", //	二维码图片
      keepPsw: true, // 记住密码

      urls: "",
      systemName: "",
      wsInit: null,
      
      isExpire: false,
      random: 0,
      qrCode: "", //	微信登陆二维码code
      isShow: "wx",
      locationHref: "", //	需要重新登录之后 跳转的路径
    };
  },
  computed:{
  	qrcodeSizeFnc(){
  		return function(){
  			return 0.125 * document.body.clientWidth + '';
  		}
  	}
  },
  methods: {
    getSnow(left, height, src) {
      var div = document.createElement("div");
      var img = document.createElement("img");
      img.className = "snow_img";
      div.appendChild(img);
      img.src = src;
      div.style.position = "fixed";
      div.style.left = left + "px";
      div.style.height = height + "px";
      document.getElementById("snowzone").appendChild(div);
      setTimeout(function () {
        document.getElementById("snowzone").removeChild(div);
      }, 5000);
    },
    loginyab() {
      this.logintabVal = "zhh";
      this.loginblock = false;
    },

    // 登陆方式切换
    logintab(val) {
      // toggle() {
      // },
      this.logintabVal = val;
      clearInterval(this.qrTime);
      //   this.isShow = val;
      this.isShow = val;
      if(val == 'wx'){
        this.updateQrcode();
      }
    },

    // 账号密码登录
    Login() {
      if (this.user == "") {
        this.loginblock = true;
        this.loginco = "请输入账号";
        return;
      }
      if (this.password == "") {
        this.loginblock = true;
        this.loginco = "请输入密码";
        return;
      }
      //   if (this.code == "") {
      //     this.loginblock = true;
      //     this.loginco = "请输入验证码";
      //     return;
      //   }
      let HasUrls = this.urls ?? "N";
      if (HasUrls == "N") {
        this.urls = "uc";
      }
      let params = {
        pin: this.user,
        passcode: this.password,
        type: "account",
        verifyCode: this.code,
        syscode: this.urls,
      };
      // this.$jsEncrypt(params)
      Api.ajaxRequest("sslogin", params).then((res) => {
        let {} = res;
        if (res.code == "200") {
          this.$cookies.set("token", res.token);
          this.$cookies.set("uid", res.uid);
          this.GetStyleSet(res.backurl);
          if (this.keepPsw == true) {
            localStorage.setItem("pwd", this.$jsEncrypt(this.password));
            localStorage.setItem("name", this.$jsEncrypt(this.user));
          } else {
            localStorage.setItem("pwd", "");
            localStorage.setItem("name", "");
          }
          this.WebSocketTest();
        } else {
          this.loginblock = true;
          this.loginco = res.detail + "，请重新输入";
          this.genVerifyCode();
        }
      });
    },

    // 权限模块
    permission(val) {
      let params = {};
      Api.ajaxRequests("user/query/module", params).then((res) => {
        let { code, data } = res;
        if (code == "200") {
          if (data != "" && data != null) {
            let arr = [];
            let a = val.lastIndexOf("=") + 1;
            let token = val.slice(a);
            let list = data;
            list.forEach((item, index) => {
              if (item.url != "" && item.url != null) {
                arr.push(item.url);
              }
            });
            window.location.href =
              "https://hdgy.zswl0580.cn/zhzsfx/index.html#" +
              arr[0] +
              "?token=" +
              token;
          } else {
            // window.location.href="https://syhouse.k95.cn/index.html#/"
          }

          // list.forEach((item,index)=>{
          // 	arr.push({
          // 		id: item.id,
          // 		pathUrl: item.url,
          // 		name: item.name,
          // 	})
          // })
          // this.menuArr=arr
          // this.$cookies.set('uc_MeunInd', data[0].id);
        } else if (code == "503") {
          // window.location.href="https://syhouse.k95.cn/index.html#/"
        } else {
          // window.location.href="https://syhouse.k95.cn/index.html#/"
        }
      });
    },

    // 更新微信二维码
    updateQrcode(){
      this.isExpire = false;
      this.wxLogin();
    },
    // 微信扫码登录
    wxLogin() {
			// 超过60s停止登录请求
			let endTimeout = setTimeout(()=>{
        this.isExpire = true;
				clearInterval(this.qrTime);
			},60000)
      Api.ajaxRequest('wx/get/qrcode',{
        authType: 'login',
      }).then(res => {
      	let { code,data } = res;
        if( code == 200 ){
          this.qrCode = data;
        }
      })
      this.qrTime = setInterval(() => {
        let params = {
          pin: Api.getUrlParams(this.qrCode,'authKey'),
          type: "gzhHtml",
        };
        Api.ajaxRequest("sslogin", params).then((res) => {
          if (res.code == "200") {
            this.$cookies.set("token", res.token);
            this.$cookies.set("uid", res.uid);
            this.GetStyleSet(res.backurl);
            clearInterval(this.qrTime);
		        clearTimeout(endTimeout);
          }
        });
      }, 2000);
    },

    // 更新二维码
    genVerifyCode() {
      Api.ajaxRequest("genVerifyCode").then((res) => {
        let { code, data } = res;
        if (code == "200") {
          this.assetsimages = res.data;
        }
      });
    },

    // 获取系统名字
    getSysName() {
      let urls = this.gup("syscode");
      this.urls = urls;
      let params = {
        systemId: urls,
      };
      Api.ajaxRequest("system/query/by/systemId", params).then((res) => {
        let { code, data } = res;
        if (code == "200") {
          if (res.data.systemName == null) {
            this.systemName = "统一用户中心";
          } else {
            this.systemName = res.data.systemName;
          }
        } else {
          this.systemName = "统一用户中心";
        }
      });
    },
    gup(name) {
      var regexS = "[\\?&]" + name + "=([^&#]*)"; //匹配name参数对
      var regex = new RegExp(regexS);
      var results = regex.exec(window.location.href); //过滤超链接
      if (results == null) {
        return "";
      } else {
        return results[1];
      }
    },

    WebSocketTest() {
      sessionStorage.setItem("changePsd", "N");
      var ws,
        _this = this,
        url =
          "ws://zjlw.songyang.gov.cn:8090/uc/websocket/notice/" +
          Api.getUid() +
          "/N";
      if (window.location.href.indexOf("https:") !== -1) {
        url =
          "wss://zjlw.songyang.gov.cn:8095/uc/websocket/notice/" +
          Api.getUid() +
          "/N";
      }
      // var ws,
      // 		_this = this,
      // 		url = 'ws://192.168.20.3:8081/uc/websocket/notice/'+ Api.getUid() +'/N';;
      // if(window.location.href.indexOf('https:') !== -1){
      // 	url = 'wss://192.168.20.3:8081/uc/websocket/notice/'+ Api.getUid() +'/N';
      // }
      if (url != "" && url != null) {
        if ("WebSocket" in window) {
          // 打开一个 web socket
          ws = new WebSocket(url);
          ws.onopen = function () {
            // alert("已连接");
          };
          ws.onmessage = function (evt) {
            var received_msg = JSON.parse(evt.data);
            _this.$notify({
              title: received_msg.subject,
              message: received_msg.content,
              type: "warning",
            });
            sessionStorage.setItem("changePsd", "Y");
            eventBus.$emit("changePsd", "Y");
            ws.close();
          };
          ws.onclose = function () {
            // 关闭 websocket
            // alert("连接已关闭...");
          };
          this.wsInit = ws;
        } else {
          // 浏览器不支持 WebSocket
          alert("您的浏览器不支持 WebSocket!");
        }
      }
    },

    GetStyleSet(backurl) {
      Api.ajaxRequest("style/get/config", {}).then((res) => {
        let { code, data } = res;
        if (code == "200") {
          if (data ?? false) {
            localStorage.setItem("styleValObj", data);
            if (this.locationHref ?? false) {
              window.location.href = decodeURIComponent(this.locationHref);
            } else if (this.urls ?? false) {
              window.location.href = backurl;
            } else {
              this.$router.push({
                path: "/index",
              });
            }
          }
        }
      });
    },
    getNemTIime() {
      //获取当前时间戳
      var myDate = new Date();
      var year = myDate.getFullYear(); //获取完整的年份(4位,1970-????)
      var month = Number(myDate.getMonth() + 1) + ""; //获取当前月份(0-11,0代表1月)
      var date = myDate.getDate() + ""; //获取当前日(1-31)
      //获取当前时
      var hour =
        myDate.getHours() < 10 ? "0" + myDate.getHours() : myDate.getHours();
      //获取当前分
      var minute =
        myDate.getMinutes() < 10
          ? "0" + myDate.getMinutes()
          : myDate.getMinutes();
      //获取当前秒
      var second =
        myDate.getSeconds() < 10
          ? "0" + myDate.getSeconds()
          : myDate.getSeconds();
      //获取星期
      var day = new Array(
        "星期日",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六"
      )[myDate.getDay()];
      if (date.length == 1) {
        date = "0" + date;
      }
      if (month.length == 1) {
        month = "0" + month;
      }
      //当前日期
      var now_time =
        year +
        "-" +
        month +
        "-" +
        date +
        " " +
        day +
        "  " +
        hour +
        ":" +
        minute +
        ":" +
        second;
      return now_time;
    },
  },
  created() {
    this.urls = this.$route.query.syscode;
    this.locationHref = this.gup("sysurl");
    this.auth_code = this.gup("auth_code");
    setInterval(() => {
      this.shijian = this.getNemTIime();
    }, 1000);
  },
  mounted() {
    this.updateQrcode();
    let that = this;
    setInterval(() => {
      var left = Math.random() * window.innerWidth;
      var height = Math.random() * window.innerHeight;
      var src = require("../assets/images/login/s" +
        Math.floor(Math.random() * 2 + 1) +
        ".png"); //两张图片分别为"s1.png"、"s2.png"
      that.getSnow(left, height, src);
    }, 100);
    // 获取cookie里的账号密码
    this.password = localStorage.getItem("pwd")
      ? JSON.parse(this.$jsDecrypt(localStorage.getItem("pwd")))
      : "";
    this.user = localStorage.getItem("name")
      ? JSON.parse(this.$jsDecrypt(localStorage.getItem("name")))
      : "";

    if (that.auth_code != "") {
      let params = {
        passcode: that.auth_code,
        type: "zzdapp",
        syscode: that.urls,
      };
      Api.ajaxRequest("sslogin", params).then((res) => {
        let { code, data, detail } = res;
        if (code == "200") {
          that.$cookies.set("token", res.token);
          that.$cookies.set("uid", res.uid);
          this.GetStyleSet(res.backurl);
        } else {
          that.loginblock = true;
          that.loginco = "登录异常请使用账号密码登录";
        }
      });
    } else {
      window.addEventListener("message", function (event) {
        // 这里的event.data 就是登录成功的信息
        // 数据格式：{ "code": "aaaa", "state": "bbbb" }
        let code = event.data.code;
        console.log(code, "code", event);
        if (code == undefined) {
        } else {
          let params = {
            passcode: code,
            type: "zzd",
            syscode: that.urls,
          };
          Api.ajaxRequest("sslogin", params).then((res) => {
            let { code, data, detail } = res;
            if (code == "200") {
              that.$cookies.set("token", res.token);
              that.$cookies.set("uid", res.uid);
              this.GetStyleSet(res.backurl);
            } else {
              that.loginblock = true;
              that.loginco = "登录异常请使用账号密码登录";
            }
          });
        }
      });
    }

    this.genVerifyCode();
    this.getSysName();
  },
};
</script>


  <style scoped lang="less">
// 登录提示
.loginTips {
  width: 435px;
  height: 97px;
  background-image: url(../assets/images/login/icon-loginTip.png);
  background-size: 100% 100%;
  color: #3cc1f9;
  position: fixed;
  z-index: 1000;
  left: 78%;
  top: 49%;
  transform: translate(-50%, -50%);
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 33px 20px 10px 40px;

  p {
    font-weight: bold;
    font-size: 28px;
    cursor: pointer;
  }

  span {
    font-size: 16px;
  }
}
ul li {
  list-style: none;
}
.login {
  width: 100%;
  height: 100vh;
  background-image: url(../assets/images/login/icon-01.jpg);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
  box-sizing: border-box;
  position: relative;

  // >img{
  // 	width: 148px;
  // 	height: 122px;
  // 	position: absolute;
  // 	top: 0;
  // 	left: 0;
  // 	animation: mymove1 infinite  5s;
  // }
  .head {
    width: 100%;
    display: flex;
    // align-items: center;
    justify-content: space-between;
    position: absolute;
    left: 0;
    top: 0;
    .left {
      display: flex;
      align-items: center;
      position: absolute;
      left: 30px;
      top: 10px;
      .date {
        font-size: 16px;
        margin-right: 10px;
        color: #fff;
      }
      .time {
        color: #1f72db;
        font-size: 16px;
      }
    }
    .title {
      width: 100%;
      height: 75px;
      background-image: url(.././assets/images/login/icon-02.png);
      background-size: 100% 100%;
      display: flex;
      justify-content: center;
      font-size: 24px;
      color: #fff;
      align-items: center;
      padding-top: 10px;
      box-sizing: border-box;
    }
    .right {
      width: 21px;
      height: 23px;
      background-size: 100% 100%;
      background-image: url(.././assets/images/login/icon-03.png);
      position: absolute;
      right: 30px;
      top: 18px;
    }
  }
  .login_box {
    width: 420px;
    height: 500px;
    background-color: #ffffff;
    border-radius: 8px;
    position: absolute;
    top: 300px;
    right: 220px;
    .erweima {
      cursor: pointer;
      width: 48px;
      height: 48px;
      position: absolute;
      left: 0;
      top: 0;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .title {
      width: 188px;
      height: 19px;
      margin: 69px auto 0;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .txt {
      font-size: 28px;
      color: #1a408d;
      margin: 24px auto 48px;
      text-align: center;
    }
    .userName {
      width: 322px;
      height: 61px;
      border: solid 1px #dfe3e6;
      display: flex;
      align-items: center;
      padding-left: 29px;
      margin: 0 auto 19px;
      .icon {
        width: 19px;
        height: 21px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .border {
        width: 2px;
        height: 36px;
        background-color: #e9edee;
        margin-left: 22px;
        box-shadow: 1px 0px 0px 0px rgba(255, 255, 255, 0.2);
      }
      input {
        width: 111px;
        font-size: 16px;
        color: #000;
        border: 0;
        outline: none;
        margin-left: 20px;
      }
    }
    .button {
      width: 321px;
      height: 61px;
      background-image: linear-gradient(270deg, #0f2692 0%, #0d88ff 100%),
        linear-gradient(270deg, #3cb1dc 0%, #84d1ed 100%),
        linear-gradient(#0d39ac, #0d39ac);
      border-radius: 30px;
      margin: 39px auto 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      color: #ffffff;
      cursor: pointer;
    }
    .erweima1 {
      width: 240px;
      height: 240px;
      margin: 29px auto 0;
      position: relative;
      .expireBox{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0%;
        left: 0%;
        background-color: rgba(255,255,255,.9);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        >img{
          width: 50px;
          height: 50px;
        }
      }
      >img{
        width: 100%;
        height: 100%;
      }
    }
    .txt1 {
      color: #1a408d;
      font-size: 24px;
      margin-top: 18px;
      text-align: center;
    }
  }
}

/deep/.snow_img {
  animation: mymove 5s;
  height: 15px;
  position: absolute;
  opacity: 0;
}

@keyframes mymove {
  0% {
    bottom: 100%;
    opacity: 0;
  }

  50% {
    opacity: 1;
    transform: rotate(1080deg);
  }

  100% {
    transform: rotate(0deg);
    opacity: 0;
    bottom: 0;
  }
}
// @keyframes mymove1 {
// 	0% {
// 		top: 300px;
// 		left: 300px;
// 		transform: scale(.1);
// 	}

// 	100% {
// 		top: 0px;
// 		left: -200px;
// 		transform: scale(1);

// 	}
// }
</style>
  